body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.error{
  font-size: 12px;
  font-style: italic;
}

.elmo-datatable-table {
  width: 100%;
}

tr.elmo-datatable__tr {
  border-bottom: 1px solid rgba(0,0,0,.14);
}

.elmo-datatable-table td, .elmo-datatable-table th {
  min-width: 100px;
}

.elmo-datatable__empty {
  padding: 12px;
}

.elmo-checkbox__icon {
  border: 1px solid #545454;
}

/**
 * These are temporary solution to make DEMO looks a bit nicer!
 */

#elmo-navigation-menu {
  background-color: white !important;
}

#elmo-navigation-menu > div:first-child {
  width: 100%;
}

#elmo-navigation-menu > div:first-child > div {
  border: none;
}

#root {
  border-left: 1px solid rgb(204, 204, 204);
}

#root .elmo-layout__main-content {
  background-color: #F5F5F5;
}

.elmo-link {
  cursor: pointer;
}
.inputTags .react-select__menu {
  display: none;
}

.inputTags .react-select__indicator.react-select__dropdown-indicator {
  display: none;
}
.textCenter {
  text-align: center;
}

.elmo-search__wrapper {
  background: none;
  padding-right: 0;
}
#viewDetailsModal .elmo-dl__dtm,
#viewDetailsModal p {
  margin-bottom: 10px;
}

#viewDetailsModal hr {
  border-bottom: 1px solid #dbdbdb;
}
.elmo-modal-header-title-full .elmo-modal__header-title {
  width: 100%;
}

.elmo-modal-header-title-full h3.elmo-typography {
  line-height: 48px;
}

#modalListFilters .elmo-modal__body {
  padding-right: 12px;
}
.filter-control {
  background: white;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.14);
}

.filter-control .elmo-btn.elmo-dropdown__button {
  cursor: pointer;
}

.filter-control .elmo-icon.float-right {
  float: right;
  margin-left: auto;
}
.flag-center {
    margin: -0.5rem 0rem 0rem -0.5rem;
}
:after, :before {
    box-sizing: border-box
}

html {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

@-ms-viewport {
    width: device-width
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #212121;
    text-align: left;
    background-color: #f6f6f6
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
    margin-bottom: .5em
}

abbr[data-original-title], abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    font-style: normal;
    line-height: inherit
}

address, dl, ol, ul {
    margin-bottom: 1rem
}

dl, ol, ul {
    margin-top: 0
}

ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 600
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

dfn {
    font-style: italic
}

b, strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #19579f;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #2972c4
}

a:hover, a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code, kbd, pre, samp {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    border-style: none
}

img, svg {
    vertical-align: middle
}

svg {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #000;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5em
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}
