body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.error{
  font-size: 12px;
  font-style: italic;
}

.elmo-datatable-table {
  width: 100%;
}

tr.elmo-datatable__tr {
  border-bottom: 1px solid rgba(0,0,0,.14);
}

.elmo-datatable-table td, .elmo-datatable-table th {
  min-width: 100px;
}

.elmo-datatable__empty {
  padding: 12px;
}

.elmo-checkbox__icon {
  border: 1px solid #545454;
}
