/**
 * These are temporary solution to make DEMO looks a bit nicer!
 */

#elmo-navigation-menu {
  background-color: white !important;
}

#elmo-navigation-menu > div:first-child {
  width: 100%;
}

#elmo-navigation-menu > div:first-child > div {
  border: none;
}

#root {
  border-left: 1px solid rgb(204, 204, 204);
}

#root .elmo-layout__main-content {
  background-color: #F5F5F5;
}

.elmo-link {
  cursor: pointer;
}