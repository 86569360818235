.filter-control {
  background: white;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.14);
}

.filter-control .elmo-btn.elmo-dropdown__button {
  cursor: pointer;
}

.filter-control .elmo-icon.float-right {
  float: right;
  margin-left: auto;
}