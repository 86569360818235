.elmo-modal-header-title-full .elmo-modal__header-title {
  width: 100%;
}

.elmo-modal-header-title-full h3.elmo-typography {
  line-height: 48px;
}

#modalListFilters .elmo-modal__body {
  padding-right: 12px;
}